<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import * as moment from 'moment'

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Users",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, DatePicker },
  data() {
    return {
      title: "List Of Users",
      items: [
        {
          text: "Dashboard",
          href: "/home"
        },
        {
          text: "Users",
          active: true
        }
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "registerAt",
      sortDesc: false,
      fields: [
        'No',
        { key: "firstname", label: "Name", sortable: true },
        { key: "email", sortable: true },
        { key: "phone", sortable: true },
        { key: "gender", sortable: true },
        { key: "birthDate", label: "Date of Birth", sortable: true },
        { key: "address", sortable: true },
        'Action'
      ],
      deleteId: null,
      isFileToImportEmpty: false,
      attachment: null,
      errorMessage: null,
      verifiedId: null,
      date: [],
      isEmptyDate: false,
      loading: false,
    };
  },
  methods: {
    async fetchUsers(ctx) {
      console.log(ctx)
      const promise = this.$axios.get(`user`, {
        params: {
          page: ctx.currentPage,
          limit: ctx.perPage,
          search: ctx.filter,
          sortBy: ctx.sortBy,
          sortType: (ctx.sortDesc)? 1 : -1
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        const items = response.data.result.users
        this.totalRows = response.data.result.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    confirmDelete(userId) {
      this.deleteId = userId
      this.$bvModal.show('modal-delete')
    },
    deleteData() {
      this.$axios.delete(`user/${this.deleteId}`)
        .then( response => {
          console.log(response.data)
          Swal.fire("Success", "User Deleted Successfully", "success");
          this.$refs.table.refresh();
        }).catch( error => {
          console.log(error.response)
        })
    },
    downloadTemplate() {
      window.location.href =`${process.env.VUE_APP_BASE_URL_API}/sample/template_data_user.xlsx`
    },
    upload(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['xlsx','xls']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.attachment = event.target.files[0]
          this.errorMessage = null
          this.isFileToImportEmpty = false
        }else{
          this.isFileToImportEmpty = true
          this.errorMessage = "file extentions not allowed"
        }
      }
    },
    importFile(){
      if (!this.attachment) {
        this.isFileToImportEmpty = true
        this.errorMessage = "file is required"
      } else {
        this.isFileToImportEmpty = false
        this.errorMessage = null

        let param = new FormData();
        param.append('attachment', this.attachment)

        const loader = this.$loading.show();
        this.$axios.post('/user/import', param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "Data User Imported Successfully", "success");
          this.attachment = null
          this.$bvModal.hide("modal-import")
          loader.hide();
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
            loader.hide();
          }
        })
      }
    },
    async generateReport() {
      this.isEmptyDate = false;

      let query = "";

      if (this.sortBy) query += `&sortBy=${this.location}`;
      if (this.sortDesc) query += `&sortType=${this.sortDesc ? 1 : -1}`;
      if (this.filter) query += `&search=${this.filter}`;

      if (this.date.length > 0 && this.date[0]) query += `&startDate=${this.date[0]}&endDate=${this.date[1]}`;
      else {
        this.isEmptyDate = true;
        return;
      }

      this.loading = true;
      const downloadUrl =`${process.env.VUE_APP_BASE_URL_API}/user/export/list?${query}`;

      try {
        const response = await this.$axios.get(downloadUrl, {
          responseType: 'blob', // Important for downloading files
          // onDownloadProgress: (progressEvent) => {
          //   // Calculate download progress as a percentage
          //   _.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // },
        });

        // Create a URL for the blob and trigger the download
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export-user-${moment(this.date[0]).format('YYYYMMDD')}-${moment(this.date[1]).format('YYYYMMDD')}.xlsx`); // Change as needed
        document.body.appendChild(link);
        link.click();
        
        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Download failed:", error);
      } finally {
        this.loading = false;
        // this.progress = 0;
      }
    },
    confirmVerified(user) {
      Swal.fire({
          title: "Are you sure?",
          text: "You want to resent email verification to this user",
          icon: "warning",
          confirmButtonText: "Yes",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        }).then((result) => {
          if (result.value) {
            this.verifiedId = user.userId
            this.verifiedUser()
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.verifiedId = null
          }
        })
    },
    verifiedUser() {
      const form = new FormData();
      form.append('userId', this.verifiedId)
      this.$axios.post(`auth/resend/email-verification`, form)
        .then( response => {
          console.log(response.data)
          Swal.fire("Success", "verification email sent successfully", "success");
          this.$refs.table.refresh();
        }).catch( error => {
          console.log(error.response)
        })
    }
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ title }}</h4>
          <div class="page-title-right">
            <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-right d-none d-md-block">
          <b-button right variant="outline-primary" class="mr-1" @click="$bvModal.show('modal-export')">Export Data User</b-button>
          <b-button v-if="$store.getters.checkPermission('IMPORT_USER')" right variant="outline-primary" class="mr-1" @click="$bvModal.show('modal-import')">Import Data User</b-button>
          <b-button v-if="$store.getters.checkPermission('CREATE_USER')" right variant="primary" :to="'/user/create'">Create User</b-button>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchUsers"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>
                <!-- Full Name -->
                <template v-slot:cell(firstname)="data">
                  <b>{{ data.item.firstname }} {{ data.item.lastname }}</b>
                </template>
                <!-- Email -->
                <template v-slot:cell(email)="data">
                  {{ data.item.email }}<br>
                  <b-badge variant="warning" v-if="data.item.verified">verified</b-badge>
                  <b-badge variant="danger" class="unverified" @click="confirmVerified(data.item)" v-else>unverified</b-badge>
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">
                  <b-button class="m-1" v-if="$store.getters.checkPermission('HISTORY_APPOINTMENT_USER')" variant="outline-success" size="sm" :to="`/user/${data.item.userId}/history-appointment`">History Appointment</b-button>
                  <b-button class="m-1" v-if="$store.getters.checkPermission('HISTORY_PURCHASE_USER')" variant="outline-secondary" size="sm" :to="`/user/${data.item.userId}/history-purchase`">History Purchase</b-button>
                  <b-button class="m-1" v-if="$store.getters.checkPermission('CHECKOUT_PACKAGE_USER')" variant="outline-warning" size="sm" :to="`/user/${data.item.userId}/package`">Buy Package</b-button>
                  <b-button class="m-1" v-if="$store.getters.checkPermission('PROGRESS_NOTE_USER')" variant="outline-info" size="sm" :to="`/user/${data.item.userId}/medical-record`">Medical Record</b-button>
                  <b-button class="m-1" v-if="$store.getters.checkPermission('UPDATE_USER')" variant="outline-primary" size="sm" :to="`/user/edit/${data.item.userId}`">Edit</b-button>
                  <b-button class="m-1" v-if="$store.getters.checkPermission('DELETE_USER')" variant="outline-danger" size="sm" @click.prevent="confirmDelete(data.item.userId)">Delete</b-button>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-delete"
      centered
      title="Warning!"
      title-class="font-18"
      @cancel="deleteId = null"
      @ok="deleteData"
    >
      <p>are you sure you want to delete this data ?</p>
    </b-modal>

    <b-modal
      id="modal-import"
      centered
      title="Import Data User"
      title-class="font-18"
      :hide-footer="true"
    >
      <form action="#" @submit.prevent="importFile">
        <div class="form-group">
          <label>File</label>
          <input
            id="file"
            type="file"
            class="form-control"
            name="file"
            :class="{ 'is-invalid': isFileToImportEmpty }"
            aria-describedby="file-help-block"
            @change="upload"
          />
          <b-form-text id="file-help-block">
            Please import file using template we have provided
          </b-form-text>
          <div v-if="isFileToImportEmpty" class="invalid-feedback">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div class="form-group mb-0">
          <div>
            <button type="submit" class="btn btn-primary">Submit</button>
            <b-button style="float: right" variant="outline-primary" @click="downloadTemplate">Download Template</b-button>
          </div>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-export"
      centered
      title="Export Data User"
      title-class="font-18"
      :hide-footer="true"
    >
      <form action="#" @submit.prevent="generateReport">
        <div class="form-group">
          <label>Transaction Date</label>
          <date-picker
            v-model="date"
            range
            format="DD MMMM YYYY"
            name="date"
            :first-day-of-week="1"
            lang="en"
            placeholder="Select Date"
            :class="{ 'is-invalid': isEmptyDate }"
          ></date-picker>
          <b-form-text id="file-help-block">
            Please filter transaction date
          </b-form-text>
          <div v-if="isEmptyDate" class="invalid-feedback">
            <span>Date is required</span>
          </div>
        </div>
        <div class="form-group mb-0">
          <div>
            <button type="submit" class="btn btn-primary" :disabled="loading">{{ loading ? `Generating...` : 'Export' }}</button>
          </div>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style scoped>
.unverified:hover {
  cursor: pointer; 
}
</style>